<template>
  <div class="modal">
    <div class="modal__wrapper">
      <button class="close modal__close" @click="$emit('on-close')">
        <fa-icon icon="times"></fa-icon>
      </button>
      <div class="modal__title">{{ $t("compta.title") }}</div>
      <div class="modal__body">
        <p>
          {{ $t("compta.text") }}
        </p>
        <form @submit.prevent="">
          <label>{{ $t("data.from") }}</label>
          <input type="number" class="input" v-model.number="from" />
          <label>{{ $t("data.to") }}</label>
          <input type="number" class="input" v-model.number="to" />
          <button
            :disabled="!(from && to)"
            :class="{ 'button--disabled': !(from && to) }"
            class="button"
            @click="exportCompta('France')"
          >
            FRANCE
          </button>
          <button
            :disabled="!(from && to)"
            :class="{ 'button--disabled': !(from && to) }"
            class="button"
            @click="exportCompta('Monaco')"
          >
            MONACO
          </button>
        </form>
        <div class="loader" v-if="loading">
          <div class="loading">
            <fa-icon icon="spinner" class="loading__spinner" spin></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ComptaExport",
  data() {
    return {
      fileRoot: `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}`,
      loading: false,
      from: "",
      to: "",
    };
  },
  methods: {
    async exportCompta(loc) {
      this.loading = true;
      try {
        const data = await this.$store.dispatch(`invoice/exportCompta${loc}`, {
          from: this.from,
          to: this.to,
        });
        if (data.csv) {
          window.open(`${this.fileRoot}/${data.csv}`);
        } else if (data.txt) {
          window.open(`${this.fileRoot}/${data.txt}`);
        }
      } catch (e) {
        alert(e);
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.modal__body {
  position: relative;
}
form {
  position: relative;
  align-items: center;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-gap: 0.5rem;
}
.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.6);
}
</style>
