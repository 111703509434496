export default {
    staff(state){
        return state.staff;
    },
    hasStaff(state){
        return state.staff && state.staff.length;
    },

    pages(state){
        return state.pages;
    },

    costCodes(state){
        return state.costCodes;
    }
}