import mutations from "./mutations";
import actions from './actions';
import getters from './getters';

export default{
    namespaced: true,
    state(){
        return {
            ritten: [],
            numbers: null,
            pages: 0,
            wagens: ['Executive', 'Luxury', 'Luxury VIP', 'Mini-Van', 'Bus', 'External'],
            bedrijven: [],
            customers: [],

            activeRit: null,
            activeSubReizigers: [],
            alarm:null,
            lastChauffeur: null,
            chauffeurs: [],
            activeChauffeur: null,
            tasks: []
        }
    },
    mutations,
    actions,
    getters
}