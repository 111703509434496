<template>
  <div class="navigation" v-if="user && user.rights" :class="{'navigation--visible':visible}">
    <!--<div class="navigation__info">
      <a :href="`doc/dispatch_${$i18n.locale}.pdf`" target="_blank" :title="$t('navigation.manual')"><fa-icon icon="question-circle"></fa-icon></a>
    </div>-->
    <div class="title navigation__title">{{ $t('navigation.title') }}</div>
    <router-link class="navigation__link" to="/dispatch" @click.native="visible=false" v-if="user.rights.dispatch">{{ $t('navigation.dp') }}
    </router-link>
    <router-link class="navigation__link" to="/customers" @click.native="visible=false" v-if="user.rights.customers">{{ $t('navigation.cust') }}</router-link>
    <router-link class="navigation__link" to="/hotels" @click.native="visible=false" v-if="user.rights.customers">{{ $t('navigation.hotels') }}</router-link>
    <router-link class="navigation__link" to="/staff" @click.native="visible=false" v-if="user.rights.staff">{{ $t('navigation.pers') }}</router-link>
    <router-link class="navigation__link" to="/cars" @click.native="visible=false" v-if="user.rights.cars">{{ $t('navigation.cars') }}</router-link>
    <router-link class="navigation__link" to="/tarifs" @click.native="visible=false" v-if="user.rights.validation">{{ $t('navigation.tarifs') }}</router-link>
    <router-link class="navigation__link" to="/costs" @click.native="visible=false" v-if="user.rights.invoicing">{{ $t('navigation.costs') }}</router-link>
    <router-link class="navigation__link" to="/invoicing" @click.native="visible=false" v-if="user.rights.invoicing">{{ $t('navigation.invoicing') }}</router-link>
    <div class="navigation__link" @click="$emit('invoice-summary')" v-if="user.rights.invoicing">Aperçu commande</div>
    <router-link class="navigation__link" to="/salaries" @click.native="visible=false" v-if="user.rights.invoicing">{{ $t('navigation.salaries') }}</router-link>
    <router-link class="navigation__link" to="/pay-overview" @click.native="visible=false" v-if="user.rights.invoicing">{{ $t('navigation.payoverview') }}</router-link>
    <router-link class="navigation__link" to="/revenue" @click.native="visible=false" v-if="user.rights.invoicing">{{ $t('navigation.revenue') }}</router-link>
    <div class="navigation__link" @click="$emit('export-clicked')" v-if="user.rights.invoicing">{{$t('compta.title')}}</div>
    <router-link class="navigation__link" to="/intranet-users" @click.native="visible=false" v-if="user.rights.intranet_users">{{ $t('navigation.users') }}</router-link>
    <div class="navigation__link" @click="$emit('support-clicked')">{{ $t('navigation.support') }}</div>
    <div class="navigation__alarms" v-if="alarm">
      <div class="navigation__alarms__alarm" :class="{'navigation__alarms__alarm--red':tasksAlert}" :title="$t('navigation.tasks')" @click="$emit('tasks-clicked')">
        <fa-icon icon="tasks"></fa-icon>
      </div>
      <div class="navigation__alarms__alarm navigation__alarms__alarm--spoed" :title="`${alarm.aantalspoed} ${$t('navigation.urgent')}`" :class="{'navigation__alarms__alarm--green':alarm.spoedalarm==='green',
      'navigation__alarms__alarm--red':alarm.spoedalarm==='red',
      'navigation__alarms__alarm--yellow':alarm.spoedalarm==='yellow'}">
        <fa-icon icon="exclamation-triangle"></fa-icon>
      </div>
      <div class="navigation__alarms__alarm navigation__alarms__alarm--sms"  :title="$t('navigation.smsStatus')" @click="$emit('sms-clicked')" :class="{'navigation__alarms__alarm--green':alarm.smsalarm==='green',
      'navigation__alarms__alarm--red':alarm.smsalarm==='red',
      'navigation__alarms__alarm--yellow':alarm.smsalarm==='yellow'}">
        <fa-icon icon="mobile-alt"></fa-icon>
      </div>
      <PrintSelector />
      <LanguageSelector />
    </div>
    <div class="navigation__toggle" @click="visible=!visible">
      <fa-icon icon="chevron-left" v-if="visible"></fa-icon>
      <fa-icon icon="chevron-right" v-else></fa-icon>
    </div>
    <div class="navigation__version">
      <span>v.{{ version }}</span>
    </div>
    <div class="navigation__overlay" @click="visible=false"></div>
  </div>
</template>

<script>
import LanguageSelector from "@/components/LanguageSelector";
import PrintSelector from "./PrintSelector";
export default {
  name: "Navigation",

  props:{
    alarm:{},
    tasks:{}
  },

  components:{
    LanguageSelector,
    PrintSelector
  },

  data() {
    return {
      visible: true,
      version: process.env.VERSION
    };
  },

  computed:{
    user(){
      return this.$store.getters['user/userData'];
    },
    tasksAlert(){
      return this.tasks.some(t => t.status !== 'DONE');
    }
  }
}
</script>