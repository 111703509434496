export default{
    setTarifCodes(state, {bedrijf,data}){
        state.tarifCodes[bedrijf]=data;
    },
    setInvoices(state,payload){
        state.invoices = payload;
    },
    setPages(state, payload){
        state.pages = payload;
    }
}