export default {
    cars(state){
        return state.cars;
    },
    hasCars(state){
        return state.cars && state.cars.length;
    },

    pages(state){
        return state.pages;
    }
}