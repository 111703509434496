import apihelper from "@/helpers/apihelper";

export default{
    async getRitten(context, {PageNumber,PageSize,query,ORDERBY,SORTORDER}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leesrittenscherm`;
        if(ORDERBY.toUpperCase() === "DATUM"){
            SORTORDER = "";
        }
        let filter1;
        if (query.filter1) {
            let f1 = query.filter1.split(' ').join('');
            filter1 = f1.split(',');
        }
        const datum = [];
        if (query.datumV) {
            datum.push(query.datumV);
        }
        if (query.datumT) {
            datum.push(query.datumT);
        }
        const payload = {
            PageNumber,
            PageSize,
            table: "DISPATCHRITTEN",
            ORDERBY: `${ORDERBY ? ORDERBY:""} ${SORTORDER?SORTORDER:""}`,
            id: query.id || "",
            bedrijf: query.bedrijf,
            filter1,
            vluchtnr: query.vluchtnr || "",
            linkklant: query.linkklant || "",
            buitenland: query.buitenland || "",
            datum,
            find: query.find || "",
            soortwagen: query.soortwagen,
            combicomfort: query.combicomfort,
            nocombi: query.nocombi,
            geenoa: query.geenoa,
            gewist: query.gewist?[0,1]:[0],
            templinkchauffeur: query.templinkchauffeur
        };
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to fetch dispatch ritten.'
        });
        context.commit('setRitten', responseData.data);
        context.commit('setNumbers', {count:responseData.metadata.count,aantallen:responseData.metadata.aantallen});
        context.commit('setPages', Math.ceil(responseData.metadata.count / responseData.metadata.PageSize));
    },

    async printRitten(context, {query,ORDERBY,SORTORDER}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/printrittenscherm`;
        if(ORDERBY.toUpperCase() === "DATUM"){
            SORTORDER = SORTORDER === "DESC" ? "ASC" : "DESC";
        }
        let filter1;
        if (query.filter1) {
            let f1 = query.filter1.split(' ').join('');
            filter1 = f1.split(',');
        }
        const datum = [];
        if (query.datumV) {
            datum.push(query.datumV);
        }
        if (query.datumT) {
            datum.push(query.datumT);
        }
        const payload = {
            table: "DISPATCHRITTEN",
            ORDERBY: `${ORDERBY ? ORDERBY:""} ${SORTORDER?SORTORDER:""}`,
            id: query.id || "",
            bedrijf: query.bedrijf,
            filter1,
            vluchtnr: query.vluchtnr || "",
            linkklant: query.linkklant || "",
            buitenland: query.buitenland || "",
            datum,
            find: query.find || "",
            soortwagen: query.soortwagen,
            combicomfort: query.combicomfort,
            nocombi: query.nocombi,
            geenoa: query.geenoa,
            templinkchauffeur: query.templinkchauffeur
        };
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to print dispatch ritten.'
        });
        return responseData.data;
    },

    clearRitten(context){
        context.commit('setRitten',[]);
        context.commit('setPages',0);
        context.commit('setNumbers',null);
    },

    async freeSearch(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/search?q=${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch freesearch results.'});
        return responseData.data;
    },

    async getBedrijven(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leesbedrijven`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch dispatch bedrijven.'});
        context.commit('setBedrijven', responseData.data);
    },

    async getCustomers(context, payload = ""){
        context.commit('setCustomers', []);
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leeshoofdklanten?q=${payload}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch dispatch hoofdklanten.'});
        context.commit('setCustomers', responseData.data);
    },

    async getActiveRit(context, payload){
        //context.commit('setActiveRit', null);
        //context.commit('setActiveSubReizigers', []);
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/allsubreizigers?linkdispatch=${payload.ID}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch dispatch rit subreizigers.'});
        context.commit('setActiveRit', payload);
        context.commit('setActiveSubReizigers', responseData.data);
    },

    async getMaxPO(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/maxPo`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch max po.'});
        return responseData.data;
    },

    async getActiveRitDetail(context){
        const token = context.rootGetters.token;
        const ritId = context.getters.activeRit.ID;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/orderdetail/${ritId}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch dispatch rit detail.'});
        return responseData.data;
    },

    clearActiveRit(context){
        context.commit('setActiveRit', null);
        context.commit('setActiveSubReizigers', [])
    },

    async getAddressesClient(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leesadressen/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch dispatch klant adressen.'});
        return responseData.data;
    },
    async getFacturatieAddressesClient(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leesfakturatieadressenklant?klantnr=${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch dispatch klant facturatie adressen.'});
        return responseData.data;
    },
    async getPersonAddressesClient(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leesadressenreizigers/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch dispatch klant reiziger adressen.'});
        return responseData.data;
    },

    async addDispatchRit(context, payload){
        const token = context.rootGetters.token;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        }
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/neworder`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add dispatch rit.'
        });
        return responseData.data;
    },

    async editDispatchRit(context, payload){
        const token = context.rootGetters.token;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        }
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/saveorder`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit dispatch rit.'
        });
    },

    async cloneRit(context, payload){
        const token = context.rootGetters.token;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        }
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/cloneRit`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to clone ride.'
        });
    },

    async deleteDispatchRit(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/wisrit/${id}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to delete dispatch rit.'});
    },
    async restoreDispatchRit(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/restorerit/${id}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to restore dispatch rit.'});
    },

    async toggleRitValue(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/setstatus`;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        };
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit dispatch rit property.'
        });
    },

    async toHistory(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/ritnaarhistoriek/${id}`;
        await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to prepare dispatch rit for history.'
        });
    },

    async selectChauffeur(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/selectchauffeur/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to select rit chauffeurs.'});
        return responseData.data;
    },

    async planChauffeur(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/saveChauffeur`;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        };
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to plan wagen/chauffeur.'
        });
    },

    async removeChauffeur(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/removeChauffeur`;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        };
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to remove wagen/chauffeur.'
        });
        return responseData.data;
    },

    async saveEvaluatie(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/saveEvaluatie`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to save evaluation.'
        });
    },

    async assignBusNr(context, {id,idwagen}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/assignbusnr/${id}?idwagen=${idwagen}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to assign busnr.'});
    },

    async readAlarm(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/readalarm`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to get alarm data.'});
        context.commit('setAlarm', responseData.data);
    },

    storeChauffeur(context, payload){
        context.commit('setLastChauffeur',payload);
    },

    async getChauffeurs(context, {van,tot}){
        context.dispatch('clearChauffeurs');
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/geplandechauffeurs?van=${van}&tot=${tot}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to get planned chauffeurs.'});
        context.commit('setChauffeurs', responseData.data);
    },

    async getChauffeurData(context,{templinkchauffeur,van,tot}){
        context.dispatch('clearActiveChauffeur');
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/rittenchauffeur?templinkchauffeur=${templinkchauffeur}&van=${van}&tot=${tot}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to get ritten geplande chauffeur.'});
        context.commit('setActiveChauffeur', responseData.data);
    },

    async printChauffeurData(context,{id,van,tot,template,taal}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/preview/${id}?template=${template}&van=${van}&tot=${tot}&taal=${taal}&pdf=true`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to print ritten geplande chauffeur.'});
        return responseData.data;
    },

    clearChauffeurs(context){
        context.commit('setChauffeurs', []);
    },

    clearActiveChauffeur(context){
        context.commit('setActiveChauffeur', null);
    },

    async generateFile(context, {id,template,taal}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/preview/${id}?template=${template}&taal=${taal}&pdf=true`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to generate file.'
        });
        return responseData.data;
    },

    async sendSms(context,{id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/smsrit/${id}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to send sms to chauffeur.'});
    },

    async sendMail(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/sendmail/Sendemailextended`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to send email.'
        });
    },

    async sendConfirmLinkAll(context,{van,tot}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/emailselfconfirmationlinkall?van=${van}&tot=${tot}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to send self-confirmation link to all chauffeurs.'});
    },
    async sendConfirmLinkId(context,{van,tot,id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/emailselfconfirmationlink/${id}?van=${van}&tot=${tot}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to send self-confirmation link to selected chauffeur.'});
    },

    async sendAppConfirmLinkAll(context,{van,tot}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/appselfconfirmationlinkall?van=${van}&tot=${tot}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to send app self-confirmation link to all chauffeurs.'});
    },
    async sendAppConfirmLinkId(context,{van,tot,id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/appselfconfirmationlink/${id}?van=${van}&tot=${tot}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to send app self-confirmation link to selected chauffeur.'});
    },

    async getVluchtnrs(context,{datum}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/lijstvluchtnummers?datum=${datum}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to get vluchtnummers (van datum).'});
        return responseData.data;
    },

    async getRitRoute(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/hesprice/googleroutecorrected/${id}?table=dispatchritten`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to get route data (invalid or incomplete data).'});
        return responseData;
    },
    async getRitHistory(context,{id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/leeswijzigingen/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to get rit historiek.'});
        return responseData.data;
    },

    async getOnderaannemers(context, {vip=""}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/lijstonderaannemers?vip=${vip}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to get onderaannemers.'});
        return responseData.data;
    },
    async findOA(context,{id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/searchoa/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to find OA.'});
        return responseData.data;
    },

    async getAttendanceList(context,{date}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/aanwezigheidslijst?date=${date}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch aanwezigheidslijst.'});
        return responseData.data;
    },

    async getTasks(context, {status}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/listtodo?status=${status}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch tasks.'});
        context.commit('setTasks', responseData.data);
    },
    async addTask(context, payload){
        payload.creator = context.rootGetters['user/userData'].id;
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/addTodo`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add task.'
        });
    },
    async editTask(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/editTodo`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit task.'
        });
    },
    async delTask(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/delTodo`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to delete task.'
        });
    },

    async changePo(context, {id, po}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rittenscherm/changemissionpo/${id}?po=${po}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to edit PO.'});
    },
}