import apihelper from "@/helpers/apihelper";

export default {
    async getCustomers(context, {PageNumber,PageSize,find,klantintern,OrderBy,SortOrder}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/leesKlanten`;
        if(OrderBy.toUpperCase() === "DATUM"){
            SortOrder = SortOrder === "DESC" ? "ASC" : "DESC";
        }
        const payload = {
            PageNumber,
            PageSize,
            OrderBy: OrderBy || "",
            SortOrder,
            find: find || "",
            klantintern:  klantintern || ""
        };
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to fetch customers.'
        });
        context.commit('setCustomers', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / payload.PageSize));
    },

    async getAllCustomers(context,{klantintern}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/leesKlanten`;
        const payload = {
            PageNumber:1,
            PageSize:999999,
            OrderBy: "",
            SortOrder: "",
            find: "",
            klantintern:  klantintern || ""
        };
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to fetch customers.'
        });
        return responseData.data;
    },

    clearCustomers(context){
        context.commit('setCustomers',[]);
        context.commit('setPages',0);
    },

    async getCustomerDetail(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/detailklant/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch customer detail.'});
        return responseData.data;
    },

    async getCustomerTarief(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/selecttarieven/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch customer tarief.'});
        return responseData.data;
    },
    async getCustomerPrice(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/klantprice/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch customer price.'});
        return responseData.data;
    },
    async getCustomerAddresses(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/leesadressen/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch customer addresses.'});
        return responseData.data;
    },
    async getCustomerTravelerAddresses(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/leesadressenreizigers/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch customer traveler addresses.'});
        return responseData.data;
    },
    async getCustomerInvoiceAddresses(context, id) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/leesfakturatieadressenklant?klantnr=${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch customer invoice addresses.'});
        return responseData.data;
    },
    async getCustomerRoundtripAddresses(context,id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/selectroundtripaddresses/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch customer roundtrip addresses.'});
        return responseData.data;
    },
    async getCustomerStaffelPrices(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/selectstaffelprijzen/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch customer staffel prices.'});
        return responseData.data;
    },
    async getCustomerPosttourPrices(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/selectposttourprijzen/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch customer posttour prices.'});
        return responseData.data;
    },
    async getCustomerForfaitPrices(context, id){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/selectforfaitprijzen/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch customer forfait prices.'});
        return responseData.data;
    },

    async saveCustomer(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editCustomer`;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        };
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit customer.'
        });
    },

    async addCustomer(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newCustomer`;
        payload.user = {
            id: context.rootGetters['user/userData'].id,
            name: context.rootGetters.userName
        };
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add customer.'
        });
    },
    async addAddress(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newAddress`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add address.'
        });
    },
    async editAddress(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editAddress`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit address.'
        });
    },
    async deleteAddress(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delAddress`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to delete address.'
        });
    },

    async addInvoiceAddress(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newFacturatieAddress`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add invoice address.'
        });
    },
    async editInvoiceAddress(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editFacturatieAddress`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit invoice address.'
        });
    },
    async deleteInvoiceAddress(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delFacturatieAddress`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to delete invoice address.'
        });
    },

    async addTraveler(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/newTraveller`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add traveler.'
        });
    },
    async editTraveler(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editTraveller`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit traveler.'
        });
    },
    async deleteTraveler(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/delTraveller`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to delete traveler.'
        });
    },

    async addressToTraveler(context, {id,tel,email}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/address2traveller/${id}?tel=${tel}&email=${email}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to create traveler from location.'});
    },

    async getHotels(context, {bedrijf}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/listhotels?bedrijf=${bedrijf}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch hotels.'});
        return responseData.data;
    },
    async addHotel(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/addHotel`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add hotel.'
        });
    },
    async editHotel(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/editHotel`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit hotel.'
        });
    },
    async printAddressList(context, {bedrijf}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/customers/printinvoiceaddresses?bedrijf=${bedrijf}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to print invoice addresses.'});
        return responseData.data;
    }
}