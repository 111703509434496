export default {
    setStaff(state, payload){
        state.staff = payload;
    },

    setPages(state, payload){
        state.pages = payload;
    },

    setCostCodes(state, payload){
        state.costCodes = payload;
    }
}