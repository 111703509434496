import apihelper from "@/helpers/apihelper";
export default {
    async getStaff(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/personeel/listPersoneel`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to fetch staff.'
        });
        context.commit('setStaff', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / responseData.metadata.PageSize));
    },

    async getStaffList(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/personeel/listPersoneel`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to fetch staff.'
        });
        return responseData.data;
    },

    async addStaff(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/personeel/addPersoneel`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to add staff member.'
        });
    },

    async editStaff(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/personeel/editPersoneel`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to edit staff member.'
        });
    },
    clearStaff(context){
        context.commit('setStaff', []);
        context.commit('setPages', 0);
    },

    async getCostCodes(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/listcostcodes`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch costcodes.'
        });
        context.commit('setCostCodes', responseData.data);
    },
    async addCostCode(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/addCostCode`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to add cost code.'
        });
    },
    async editCostCode(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/editCostCode`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to edit cost code.'
        });
    },
    async delCostCode(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/delCostCode`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to delete cost code.'
        });
    },

    async getStaffBreaks(context, {pers_id, code, date}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/listdriverbreaks?pers_id=${pers_id}&code=${code}&date=${date}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch breaks.'
        });
        return responseData.data;
    },
    async addStaffBreak(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/addDriverBreak`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to add break.'
        });
    },
    async editStaffBreak(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/editDriverBreak`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to edit break.'
        });
    },
    async delStaffBreak(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/delDriverBreak`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to delete break.'
        });
    },

    async getStaffCosts(context, {pers_id, date}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/listdrivercosts?pers_id=${pers_id}&date=${date}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch costs.'
        });
        return responseData.data;
    },
    async addStaffCost(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/addDriverCost`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to add cost.'
        });
    },
    async editStaffCost(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/editDriverCost`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to edit cost.'
        });
    },
    async delStaffCost(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/delDriverCost`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to delete cost.'
        });
    },

    async getDriverPayOverview(context, {pers_id, year, month}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/driverpayoverview?pers_id=${pers_id}&year=${year}&month=${month}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch pay overview.'
        });
        return responseData.data;
    },
    async getDriverCostsOverview(context, {pers_id, year, month}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/listdrivercostsmonth?pers_id=${pers_id}&year=${year}&month=${month}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch costs overview.'
        });
        return responseData.data;
    },
    async getDriverBreaksOverview(context, {pers_id, year, month, code}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/listdriverbreaksmonth?pers_id=${pers_id}&year=${year}&month=${month}&code=${code}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch breaks overview.'
        });
        return responseData.data;
    },

    async printSalaryOverview(context, {bedrijf,pers_id,year,month}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/printsalaries?bedrijf=${bedrijf}&pers_id=${pers_id}&year=${year}&month=${month}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch document.'
        });
        return responseData.data;
    },
    async printCostsOverview(context, {bedrijf,pers_id,year,month}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/lonen/printcosts?bedrijf=${bedrijf}&pers_id=${pers_id}&year=${year}&month=${month}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch document.'
        });
        return responseData.data;
    },

    async resetPassword(context, {pers_id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/personeel/resetpassword?pers_id=${pers_id}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to reset password.'
        });
        return responseData.data;
    }
}