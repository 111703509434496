import Vue from 'vue';
import App from './App.vue';
import VueColumnsResizable from './plugins/vue-columns-resizable';
import router from './router';
import {store} from "./store";
Vue.config.productionTip = false;

Vue.use(VueColumnsResizable);

import UUID from 'vue-uuid';
Vue.use(UUID);

//localisation
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
import {languages, defaultLocale} from './i18n/index.js'

const messages = Object.assign(languages);
const dateTimeFormats = {
    'nl': {
        hes: {
            year: '2-digit', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', hour12: false
        }
    },
    'fr': {
        hes: {
            year: '2-digit', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', hour12: false
        }
    }
};
const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'nl',
    messages,
    dateTimeFormats
});

//font awesome
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faUsers,
    faEye,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faPowerOff,
    faSpinner,
    faArrowCircleLeft,
    faSave,
    faHistory,
    faEdit,
    faInfoCircle,
    faTimes,
    faClone,
    faClock,
    faExclamationTriangle,
    faEuroSign,
    faBan,
    faUserTie,
    faPaperPlane,
    faTrashAlt,
    faArrowCircleUp,
    faArrowCircleDown,
    faPlusCircle,
    faSyncAlt,
    faCog,
    faExchangeAlt,
    faPrint,
    faTasks,
    faUserCheck,
    faCalculator,
    faMobileAlt,
    faProjectDiagram,
    faFileExport,
    faQuestionCircle,
    faMap,
    faShip,
    faCheck,
    faPlay,
    faMagic,
    faArrowsAlt,
    faCalendarPlus,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(
    faUsers,
    faEye,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faPowerOff,
    faSpinner,
    faArrowCircleLeft,
    faArrowCircleUp,
    faArrowCircleDown,
    faSave,
    faHistory,
    faEdit,
    faInfoCircle,
    faTimes,
    faClone,
    faClock,
    faExclamationTriangle,
    faEuroSign,
    faBan,
    faUserTie,
    faPaperPlane,
    faTrashAlt,
    faPlusCircle,
    faSyncAlt,
    faCog,
    faExchangeAlt,
    faPrint,
    faTasks,
    faUserCheck,
    faCalculator,
    faMobileAlt,
    faProjectDiagram,
    faFileExport,
    faQuestionCircle,
    faMap,
    faShip,
    faCheck,
    faPlay,
    faMagic,
    faArrowsAlt,
    faCalendarPlus
);
Vue.component('fa-icon', FontAwesomeIcon);

import vSelect from "vue-select";
import './registerServiceWorker'

Vue.component("v-select", vSelect);

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app');
