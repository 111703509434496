import apihelper from "@/helpers/apihelper";
export default {
    async getCars(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/cars/listCars`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to fetch cars.'
        });
        context.commit('setCars', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / responseData.metadata.PageSize));
    },

    async addCar(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/cars/addCar`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to add car.'
        });
    },

    async editCar(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/cars/editCar`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload:JSON.stringify(payload),
            errorMsg: 'Failed to edit car.'
        });
    },

    clearCar(context){
        context.commit('setCars', []);
    }
}