export default{
    tarifCodes(state){
        return state.tarifCodes;
    },

    invoices(state){
        return state.invoices;
    },

    pages(state){
        return state.pages;
    }
}