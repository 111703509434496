import Vue from 'vue'
import VueRouter from 'vue-router'

//pages
import Home from '@/views/Home.vue'
import Login from "@/views/Login";

//user
import {store} from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresUnauth: true
    }
  },

  {
    path: '/reset-password',
    name: 'ResetPassword',

    component: () => import('@/views/ResetPassword')
  },

  {
    path:'/dispatch',
    name:'Dispatch',
    component: () => import('@/views/Dispatch'),
    meta: {
      requiresAuth: true,
      rights:'dispatch'
    }
  },
  {
    path:'/staff',
    name:'Staff',
    component: () => import('@/views/Staff'),
    meta: {
      requiresAuth: true,
      rights:'staff'
    }
  },
  {
    path:'/cars',
    name:'Cars',
    component: () => import('@/views/Cars'),
    meta: {
      requiresAuth: true,
      rights:'cars'
    }
  },
  {
    path:'/customers',
    name:'Customers',
    component: () => import('@/views/Customers'),
    meta: {
      requiresAuth: true,
      rights:'customers'
    }
  },
  {
    path:'/hotels',
    name:'Hotels',
    component: () => import('@/views/Hotels'),
    meta: {
      requiresAuth: true,
      rights:'customers'
    }
  },
  {
    path:'/tarifs',
    name:'Tarifs',
    component: () => import('@/views/Tarifs'),
    meta: {
      requiresAuth: true,
      rights:'validation'
    }
  },
  {
    path:'/costs',
    name:'Costs',
    component: () => import('@/views/Costs'),
    meta: {
      requiresAuth: true,
      rights:'invoicing'
    }
  },
  {
    path:'/invoicing',
    name:'Invoicing',
    component: () => import('@/views/Invoicing'),
    meta: {
      requiresAuth: true,
      rights:'invoicing'
    }
  },
  {
    path:'/salaries',
    name:'Salaries',
    component: () => import('@/views/Salaries'),
    meta: {
      requiresAuth: true,
      rights:'invoicing'
    }
  },
  {
    path:'/pay-overview',
    name:'PayOverview',
    component: () => import('@/views/PayOverview'),
    meta: {
      requiresAuth: true,
      rights:'invoicing'
    }
  },
  {
    path:'/revenue',
    name:'Revenue',
    component: () => import('@/views/Revenue'),
    meta: {
      requiresAuth: true,
      rights:'invoicing'
    }
  },
  {
    path:'/intranet-users',
    name:'IntranetUsers',
    component: () => import('@/views/IntranetUsers'),
    meta: {
      requiresAuth: true,
      rights: 'intranet_users'
    }
  },
  {
    path: '/*',
    redirect: '/'
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next({
      path: '/login',
      query: {
        nextUrl: to.fullPath
      }
    });
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/');
  } else {
    if(to.meta.rights) {
      if (to.meta.rights && store.getters['user/userData']['rights'][to.meta.rights]) {
        next();
      } else {
        next('/');
      }
    }
    else{
      next();
    }
  }
});

export default router;
