import Vue from 'vue';
import VueX from 'vuex';
import authModule from './modules/auth';
import userModule from './modules/user';
import commonModule from './modules/common';
import staffModule from './modules/staff';
import dispatchModule from './modules/dispatch';
import historyModule from './modules/history';
import customerModule from './modules/customer';
import carModule from './modules/car';
import invoiceModule from './modules/invoice';

Vue.use(VueX);

export const store = new VueX.Store({
    modules:{
        auth: authModule,
        user: userModule,
        common: commonModule,
        staff: staffModule,
        dispatch: dispatchModule,
        history: historyModule,
        customer: customerModule,
        car: carModule,
        invoice: invoiceModule
    }
});