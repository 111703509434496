export default{
    countries(state){
        return state.countries;
    },
    airports(state){
        return state.airports;
    },
    hasAirports(state){
        return state.airports && state.airports.length;
    },
    printLang(state){
        return state.printLang;
    }
}