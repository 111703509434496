import apihelper from "@/helpers/apihelper";

export default {
    async getTarifCodes(context, {bedrijf}) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/listtarifcodes?bedrijf=${bedrijf}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch tarif codes.'});
        context.commit('setTarifCodes', {bedrijf: +bedrijf === 1 ? "FRANCE" : "MONACO", data: responseData.data});
    },

    async addTarifCode(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/addTarifCode`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add tarif code.'
        });
    },
    async editTarifCode(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/editTarifCode`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit tarif code.'
        });
    },

    async getTarifs(context, {cust_id}) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/listtarif?cust_id=${cust_id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch tarifs.'});
        return responseData.data;
    },

    async addTarif(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/addTarif`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add tarif.'
        });
    },
    async editTarif(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/editTarif`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit tarif.'
        });
    },

    async changeCode(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/changecode`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit mission code.'
        });
    },

    async getInvoiceLines(context, {id}) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/invoice_lines/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch invoice lines.'});
        return responseData.data;
    },
    async addInvoiceLine(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/addInvoiceLine`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add line.'
        });
    },
    async editInvoiceLine(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/editInvoiceLine`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit line.'
        });
    },
    async delInvoiceLine(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/delInvoiceLine`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to delete line.'
        });
    },

    async getInvoices(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/listInvoices`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add line.'
        });
        context.commit('setInvoices', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / responseData.metadata.PageSize));
    },
    clearInvoices(context) {
        context.commit('setInvoices', []);
        context.commit('setPages', 0);
    },


    async getInvoiceData(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/invoice/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch invoice data.'});
        return responseData.data;
    },

    async getInvoiceTotals(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/missiontotals/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to fetch invoice totals.'});
        return responseData.data;
    },

    async addInvoice(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/addInvoice`;
        const responseData = await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to add invoice.'
        });
        return responseData.data;
    },
    async editInvoice(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/editInvoice`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit invoice.'
        });
    },
    async delInvoice(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/delInvoice`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to delete invoice.'
        });
    },

    async printInvoice(context, {id, taal}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/doc2pdf/printdoc/${id}?taal=${taal}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to print invoice.'});
        return responseData.data;
    },

    async emailInvoice(context, {id, profile, taal}){
        const token = context.rootGetters.token;
        const email = context.rootGetters['user/userData'].email;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/doc2pdf/emaildoc/${id}?taal=${taal}&email=${email}&profile=${profile}`;
        await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to email invoice.'});
    },

    async assignInvoiceNr(context, {id, date, bedrijf}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/invoicenr/${id}?date=${date}&bedrijf=${bedrijf}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to assign invoice nr.'});
        return responseData.data;
    },

    async getOrders(context, {cust_id, invoice_address_id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/openorders?cust_id=${cust_id}&invoice_address_id=${invoice_address_id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to list orders available to invoice.'});
        return responseData.data;
    },

    async addOrderToInvoice(context, {id,po}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/addinvoiceorder/${id}?po=${po}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to assign order to invoice.'});
        return responseData.data;
    },
    async removeOrderFromInvoice(context, {id,po}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/delinvoiceorder/${id}?po=${po}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to remove order from invoice.'});
        return responseData.data;
    },
    async countPo(context, {po}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/countpo?po=${po}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to check invoice po availability.'});
        return +responseData.data.ritten === 1 && !+responseData.data.invoices;
    },

    async createCredit(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/invoice2cn/${id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to create credit note.'});
        return responseData.data;
    },

    async getRevenueYear(context, {year, bedrijf}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/printomzetjaar?bedrijf=${bedrijf}&year=${year}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to export year revenue.'});
        return responseData.data;
    },
    async getRevenueMonth(context, {year, month, bedrijf}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/printomzetmaand?bedrijf=${bedrijf}&year=${year}&month=${month}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to export month revenue.'});
        return responseData.data;
    },
    async getJournal(context, {from, to, bedrijf, cust_id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/printjournal?bedrijf=${bedrijf}&from=${from}&to=${to}&cust_id=${cust_id}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to export month revenue.'});
        return responseData.data;
    },

    async exportComptaFrance(context, {from,to}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/export_compta_france?from=${from}&to=${to}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to export compta France.'});
        return responseData.data;
    },
    async exportComptaMonaco(context, {from,to}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/export_compta_monaco?from=${from}&to=${to}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to export compta Monaco.'});
        return responseData.data;
    },
    async getInvoiceSummary(context, {po}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/invoices/checklist?po=${po}`;
        const responseData = await apihelper.fetchGetRequest({url, token, errorMsg: 'Failed to export invoice summary.'});
        return responseData.data;
    }
}