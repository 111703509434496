<template>
  <div class="languages">
    <button class="languages__btn"><fa-icon icon="file-export"></fa-icon></button>
    <div class="languages__content">
      <button class="languages__content__lang" :class="{'active':printLang===item}"
              v-for="item in $i18n.availableLocales" :key="item" @click="setPrintLang(item)">
        {{ item }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrintSelector",
  computed: {
    printLang() {
      return this.$store.getters['common/printLang'];
    }
  },
  methods: {
    setPrintLang(lang) {
      this.$store.dispatch('common/togglePrintLang', lang);
    }
  }
}
</script>
<style scoped>

</style>