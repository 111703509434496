<template>
  <div class="modal">
    <div class="modal__wrapper">
      <button class="close modal__close" @click="$emit('on-close')">
        <fa-icon icon="times"></fa-icon>
      </button>
      <div class="modal__title">Aperçu commande</div>
      <div class="modal__body">
        <form action="" @submit.prevent="loadSummary">
          <label>Commande</label>
          <input type="text" class="input" v-model.number="po" />
        </form>
      </div>
      <div class="modal__controls">
        <button
          @click="loadSummary"
          class="button"
          :class="{ 'button--disabled': !po || loading }"
          :disabled="!po"
        >
          Export
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InvoiceSummary",
  data() {
    return {
      po: "",
      loading: false,
    };
  },
  methods: {
    async loadSummary() {
      this.loading = true;
      try {
        const data = await this.$store.dispatch("invoice/getInvoiceSummary", {
          po: this.po,
        });
        if(data && data.xlsx){
            window.open(data.xlsx);
        }
      } catch (error) {
        alert(error);
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
form,
.modal__controls {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  align-items: center;
}
</style>
