<template>
    <div class="languages">
      <button class="languages__btn">{{$i18n.locale}}</button>
      <div class="languages__content">
        <button class="languages__content__lang" :class="{'active':$i18n.locale===item}"
             v-for="item in $i18n.availableLocales" :key="item" @click="$i18n.locale=item">
          {{ item }}
        </button>
      </div>
    </div>
</template>

<script>
    export default {
        name: "LanguageSelector"
    }
</script>